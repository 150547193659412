import { useMemo } from "react";
import NextHead from "next/head";
import { useRouter } from "next/router";
import { BRAND_LOGO_URL, PUBLIC_URL } from "@config/config";
import { defaultLanguage, languages } from "@config/i18n";
import useOnPageSeo from "@hooks/useOnPageSeo";
const siteURL = `${PUBLIC_URL || "https://reku.id"}`;
interface HeadProps extends React.PropsWithChildren<{}> {
  title?: string;
  titleSuffix?: string;
  description?: string;
  image?: string;
  variables?: Record<string, string>;
}

const Head: React.FC<HeadProps> = ({
  title: titleProp,
  titleSuffix,
  description: descriptionProp,
  children,
  image,
  variables
}: HeadProps) => {
  const router = useRouter();
  const onPageSeo = useOnPageSeo({
    variables
  });
  const locale = (router.locale ?? defaultLanguage as "en" | "id");
  const title = useMemo(() => {
    if (onPageSeo?.title[locale]) {
      return onPageSeo.title[locale];
    }

    if (typeof titleSuffix !== "undefined") {
      if (titleSuffix === "") {
        return titleProp;
      }

      return `${titleProp} ${titleSuffix}`;
    }

    return `${titleProp} - Reku`;
  }, [onPageSeo?.title, locale, titleProp, titleSuffix]);
  const description = useMemo(() => {
    if (onPageSeo?.metaDescription[locale]) {
      return onPageSeo.metaDescription[locale];
    }

    return descriptionProp;
  }, [onPageSeo?.metaDescription, locale, descriptionProp]);
  const renderedRobotsMeta = useMemo(() => {
    const robots = [];

    if (onPageSeo?.noIndex) {
      robots.push("noindex");
    }

    if (onPageSeo?.noFollow) {
      robots.push("nofollow");
    }

    if (robots.length === 0) {
      return null;
    }

    return <meta name='robots' content={robots.join(", ")} />;
  }, [onPageSeo?.noIndex, onPageSeo?.noFollow]);
  const renderedCanonical = useMemo(() => {
    if (onPageSeo?.canonical_url) {
      if (!onPageSeo.canonical_url.includes("http")) {
        return <link rel='canonical' href={`${siteURL}${onPageSeo.canonical_url}`} />;
      }

      return <link rel='canonical' href={onPageSeo.canonical_url} />;
    }

    return null;
  }, [onPageSeo?.canonical_url]);
  const renderedHrefLang = languages.map(lang => {
    const subPath = lang.code !== defaultLanguage ? `/${lang.code}` : "";
    const href = `${siteURL}${subPath}${router.asPath}`;
    return <link key={lang.code} rel='alternate' href={href} hrefLang={lang.code} />;
  });
  const softwareApplication = useMemo(() => {
    const data = {
      "@context": "https://schema.org",
      "@type": "SoftwareApplication",
      name: "Reku: Beli & Investasi Crypto",
      url: "https://play.google.com/store/apps/details?id=com.rekeningku",
      operatingSystem: "Android",
      applicationCategory: "Finance",
      aggregateRating: {
        "@type": "AggregateRating",
        ratingValue: "4.7",
        ratingCount: "33800"
      },
      offers: {
        "@type": "Offer",
        price: "0",
        priceCurrency: "IDR"
      },
      inLanguage: [{
        "@type": "Language",
        name: "Indonesia",
        alternateName: "id"
      }, {
        "@type": "Language",
        name: "English",
        alternateName: "en"
      }]
    }; // eslint-disable-next-line react/no-danger

    return <script type='application/ld+json' dangerouslySetInnerHTML={{
      __html: JSON.stringify(data)
    }} />;
  }, []);
  return <NextHead>
            <meta name='viewport' property='viewport' content='width=device-width, initial-scale=1, shrink-to-fit=no, maximum-scale=5, minimum-scale=1' />

            <meta name='description' property='description' content={description} key='description' />

            <meta name='og:type' property='og:type' content='website' key='og:type' />
            <meta name='og:site_name' property='og:site_name' content='Reku' key='og:site_name' />
            <meta name='og:image' property='og:image' content={image} key='og:image' />
            <meta name='og:title' property='og:title' content={title} key='og:title' />
            <meta name='og:description' property='og:description' content={description} key='og:description' />
            <meta name='og:url' property='og:url' content={siteURL + router.asPath} key='og:url' />

            <meta name='twitter:card' property='twitter:card' content='summary' key='twitter:card' />
            <meta name='twitter:site' property='twitter:site' content='Reku' key='twitter:site' />
            <meta name='twitter:image' property='twitter:image' content={image} key='twitter:image' />
            <meta name='twitter:image:src' property='twitter:image:src' content={image} key='twitter:image:src' />
            <meta name='twitter:title' property='twitter:title' content={title} key='twitter:title' />
            <meta name='twitter:description' property='twitter:description' content={description} key='twitter:description' />

            <link rel='apple-touch-icon' sizes='57x57' href='https://images.reku.id/favicon/apple-icon-57x57.png' />
            <link rel='apple-touch-icon' sizes='60x60' href='https://images.reku.id/favicon/apple-icon-60x60.png' />
            <link rel='apple-touch-icon' sizes='72x72' href='https://images.reku.id/favicon/apple-icon-72x72.png' />
            <link rel='apple-touch-icon' sizes='76x76' href='https://images.reku.id/favicon/apple-icon-76x76.png' />
            <link rel='apple-touch-icon' sizes='114x114' href='https://images.reku.id/favicon/apple-icon-114x114.png' />
            <link rel='apple-touch-icon' sizes='120x120' href='https://images.reku.id/favicon/apple-icon-120x120.png' />
            <link rel='apple-touch-icon' sizes='144x144' href='https://images.reku.id/favicon/apple-icon-144x144.png' />
            <link rel='apple-touch-icon' sizes='152x152' href='https://images.reku.id/favicon/apple-icon-152x152.png' />
            <link rel='apple-touch-icon' sizes='180x180' href='https://images.reku.id/favicon/apple-icon-180x180.png' />
            <link rel='icon' type='image/png' sizes='192x192' href='https://images.reku.id/favicon/android-icon-192x192.png' />
            <link rel='icon' type='image/png' sizes='32x32' href='https://images.reku.id/favicon/favicon-32x32.png' />
            <link rel='icon' type='image/png' sizes='96x96' href='https://images.reku.id/favicon/favicon-96x96.png' />
            <link rel='icon' type='image/png' sizes='16x16' href='https://images.reku.id/favicon/favicon-16x16.png' />
            <meta name='msapplication-TileColor' content='#ffffff' />
            <meta name='msapplication-TileImage' content='https://images.reku.id/favicon/ms-icon-144x144.png' />

            {renderedRobotsMeta}

            <title key='title'>{title}</title>

            {children}

            {renderedCanonical}
            {renderedHrefLang}

            {softwareApplication}
        </NextHead>;
};

Head.defaultProps = {
  title: "Harga Bitcoin & Jual Beli Bitcoin Indonesia",
  description: "Jual beli Bitcoin Indonesia, Harga Bitcoin, kripto, dan aset kripto lainnya dengan rupiah dan biaya terendah",
  titleSuffix: "",
  image: BRAND_LOGO_URL,
  variables: undefined
};
export default Head;